/* Base Styles */
.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  transition: background-color 0.5s ease; /* Smooth background transition */
}

.App-header {
  background-color: #20232a;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.5); /* Enhance shadow for more depth */
  width: 80%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out; /* Smooth scaling on hover */
}

.App-header:hover {
  transform: scale(1.05); /* Slight scale effect on hover */
}

/* Button Styles */
button {
  background-color: #61dafb;
  border: none;
  padding: 12px 24px; /* Increased padding for a better touch area */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px; /* Larger font for readability */
  margin: 10px;
  cursor: pointer;
  border-radius: 12px; /* Rounded corners */
  color: #20232a;
  font-weight: bold;
  width: auto; /* Auto width based on content */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Subtle shadow for 3D effect */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

.button:hover {
  background-color: #21a1f1; /* Lighter blue on hover */
  box-shadow: 0 5px 15px rgba(0,0,0,0.3); /* Larger shadow on hover for lift effect */
}

.button-red {
  background-color: red;
  box-shadow: 0 2px 5px rgba(255,0,0,0.2);
}

.button-red:hover {
  box-shadow: 0 5px 15px rgba(255,0,0,0.3);
}

.button-yellow {
  background-color: yellow;
  color: #20232a; /* Ensuring good contrast */
  box-shadow: 0 2px 5px rgba(255,255,0,0.2);
}

.button-yellow:hover {
  box-shadow: 0 5px 15px rgba(255,255,0,0.3);
}

.button-grey {
  background-color: grey;
  box-shadow: 0 2px 5px rgba(128,128,128,0.2);
}

/* Disabled Button Styling */
button:disabled {
  background-color: grey;
  color: #aaa; /* Lighter text color indicating it's disabled */
  cursor: not-allowed;
  box-shadow: none; /* No shadow to flatten the appearance */
}

/* Responsive Styling for Better Accessibility on Mobile Devices */
@media (max-width: 768px) {
  .App-header {
    padding: 10px;
  }

  h1 {
    font-size: 18px;
  }

  button {
    font-size: 16px;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .App {
    padding: 10px;
  }

  .App-header {
    border-radius: 8px;
  }

  h1 {
    font-size: 16px;
  }

  button {
    width: 100%; /* Full width for easier accessibility */
    padding: 12px;
    font-size: 16px;
  }
}

